<template>
    <div class="vx-card p-8 pt-0">
        <LabelForm>{{ $t('shops.type') }}</LabelForm>
        <vs-select
            class="w-30"
            v-if="!isEdit"
            @input="handleScopeTypeChange"
            v-model="role.scope_type.id"
        >
            <vs-select-item
                v-for="(scopeTypeOption,index) in scopeTypesOptions"
                :key="index" :value="scopeTypeOption.value"
                :text="scopeTypeOption.text"
            />
        </vs-select>
        <p v-else>{{ role.scope_type.name | capitalize }}</p>

        <LabelForm>{{ $t('shops.name') }}</LabelForm>
        <vs-input
            type="text"
            class="w-30 border-grey-light"
            v-model="role.name"
            :required="true"
        />

        <FeaturesCheckboxes
            ref="featuresCheckboxes"
            :object="role"
        />

        <div class="flex flex-row gap-4 mt-12">
            <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="handleCancel">
                {{ $t('apps.modals.cancel')}}
            </button>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleSave">
                {{ $t('Save')}}
            </button>
        </div>
    </div>
</template>

<script>

import loader from "@/modules/Shared/Mixins/loader";
import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";
import FeaturesCheckboxes from "@/modules/Admin/Components/FeaturesCheckboxes.vue";

export default {
  name: "RoleForm",
  components: {FeaturesCheckboxes, LabelForm},
  mixins: [loader],
  props: {
      role: {
          type: Object,
          required: true,
      },
      isEdit: {
          type: Boolean,
          default: false
      }
  },
  data() {
    return {
      scopeTypesOptions: [
          { value: 1, text: 'application' },
          { value: 2, text: 'resort' },
          { value: 3, text: 'admin' },
      ]
    }
  },
  methods: {
      handleScopeTypeChange(scopeTypeId) {
          const scopeType = this.scopeTypesOptions.find(({value}) => value === scopeTypeId)
          this.role = {
              ...this.role,
              scope_type: {
                  id: scopeTypeId,
                  name: scopeType.text
              }
          }
      },
      handleCancel() {
          this.$router.push({ name: 'admin-roles', params: { scopeTypeId: this.role.scope_type.id } });
      },
      async handleSave() {
          await this.loadAndNotify(async () => {
              let response;
              const name = this.role.name
              const enabledFeatures = this.$refs.featuresCheckboxes.getEnabledFeatures().map(featureId => ({ id : featureId }))

              if (this.isEdit) {
                  response = await AuthorizationService.editRole(this.role.id, {
                      name: name,
                      enabled_features: enabledFeatures,
                  })
              } else {
                  response = await AuthorizationService.createRole({
                      name: name,
                      scope_type_id: this.role.scope_type.id,
                      enabled_features: enabledFeatures
                  })
              }

              if (response.code !== 200) {
                  return
              }

              await this.$router.push({ name: 'admin-roles', params: { scopeTypeId: this.role.scope_type.id } });
          }, false)
      }
  }
};
</script>
