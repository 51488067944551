var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-card p-8 pt-0" },
    [
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("shops.type")))]),
      !_vm.isEdit
        ? _c(
            "vs-select",
            {
              staticClass: "w-30",
              on: { input: _vm.handleScopeTypeChange },
              model: {
                value: _vm.role.scope_type.id,
                callback: function($$v) {
                  _vm.$set(_vm.role.scope_type, "id", $$v)
                },
                expression: "role.scope_type.id"
              }
            },
            _vm._l(_vm.scopeTypesOptions, function(scopeTypeOption, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: {
                  value: scopeTypeOption.value,
                  text: scopeTypeOption.text
                }
              })
            }),
            1
          )
        : _c("p", [
            _vm._v(_vm._s(_vm._f("capitalize")(_vm.role.scope_type.name)))
          ]),
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("shops.name")))]),
      _c("vs-input", {
        staticClass: "w-30 border-grey-light",
        attrs: { type: "text", required: true },
        model: {
          value: _vm.role.name,
          callback: function($$v) {
            _vm.$set(_vm.role, "name", $$v)
          },
          expression: "role.name"
        }
      }),
      _c("FeaturesCheckboxes", {
        ref: "featuresCheckboxes",
        attrs: { object: _vm.role }
      }),
      _c("div", { staticClass: "flex flex-row gap-4 mt-12" }, [
        _c(
          "button",
          {
            staticClass: "sk-btn sk-btn-primary rounded-lg btn-border",
            on: { click: _vm.handleCancel }
          },
          [_vm._v(" " + _vm._s(_vm.$t("apps.modals.cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "sk-btn sk-btn-primary rounded-lg",
            on: { click: _vm.handleSave }
          },
          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }